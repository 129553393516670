import { Button, Hidden, Typography } from "@material-ui/core";
import { ArrowRightAlt as ArrowIcon } from "@material-ui/icons";
import { navigate } from "gatsby";
import React from "react";
import lineIcons from "../assets/images/line-icons.png";
import notFoundToyImage from "../assets/images/not-found-page-toy-image.png";
import DefaultLayout from "../layouts/default-layout";
import useStyles from "../styles/404/styles";

function AssinaturaRecebida() {
  const classes = useStyles();
  return (
    <DefaultLayout>
      <script>
        {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '496728324797286');
fbq('track', 'PageView');
fbq('track', 'Purchase');
fbq('trackCustom', 'AssinantesSmartBox');
`}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=496728324797286&ev=PageView&noscript=1"
/>`}
      </noscript>

      <div className={classes.notFoundPage}>
        <Hidden smDown>
          <img src={lineIcons} alt="" className={classes.lineIcons} />
        </Hidden>
        <div className={classes.notFoundTextArea}>
          <div>
            <img
              src={notFoundToyImage}
              alt="Página não encontrada"
              className={classes.notFoundToyImage}
            />
          </div>
          <div className={classes.notFoundPageTextContainer}>
            <Typography variant="h1" className={classes.notFoundPageTitleText}>
              Assinatura recebida, sejam bem-vindos!
            </Typography>
            <Typography
              variant="h6"
              className={classes.notFoundPageSubtitleText}
            >
              Em breve você receberá a sua nota fiscal por e-mail, e em até dois
              dias úteis enviaremos a sua primeira Box!
            </Typography>
            <div className={classes.initialPageButtonContainer}>
              <Button
                variant="contained"
                endIcon={<ArrowIcon />}
                className={classes.initialPageButton}
                onClick={() => navigate("/")}
              >
                Página inicial
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default AssinaturaRecebida;
